import React, {useState,useEffect} from 'react';
import { withRouter ,Redirect} from "react-router-dom";
import {URL_EXAM_END, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';

const Users =(props)=>{
    const [ModalNew , setModalNew] = useState(false)
    const [search, setSearch] = useState([])
    const [users,  setUsers] = useState([])
    const [ifsearch, setifSearch] = useState(false)

    useEffect(async () => {

        props.error404(false)
        props.updateTitle('users')
        const fetchData = async () => {
            fetch(URL_EXAM_END+"/users")
            .then(res => res.json())
            .then(
              (res) => {
                //   console.log("1 ------users----->",res)
                // setIsLoaded(true);
                setUsers(res.data);
              },
              (error) => {
                // setIsLoaded(true);
                // setError(error);
              }
         )
      }  
        fetchData();
   }, [])

    const setSveModalClose =()=>{
        setModalNew(!ModalNew)
    }


    const SearchArticle = (data) =>{
        // console.log("----=--------->",data.target.value)
        // setTitle(data.target.value)
       
        fetch(URL_EXAM_END+`/users/search?search=${data.target.value}`)
        .then(res => res.json())
        .then((res)=>{
        
            if(res.status === true){
                setifSearch(true)
                setSearch(res.data)
            }
        })
     }

     const ClickUser=(data)=>{

     }

    return(
        <div className="articles">
            <br/>
             <div className="users-search">
                    <div className="users-search-sub">
                    <div class="field has-addons">
                        <div class="control users-input-search">
                            <input class="input" onChange={SearchArticle} type="text" placeholder="Search User"/>
                        </div>
                        <div class="control">
                        <button class="button is-rounded users-color">Search</button>
                        </div>
                    </div>
                    {
                        ifsearch ? 
                        <div className="user-list-div">
                
                               <div className="article-users-title">
                                     <div className="article-name">Username</div>
                                      <div className="article-email"> Email</div>
                                </div>
                              <div>
                                  <ul>
                                  {
                                      search.map(item => (
                                          
                                       <li>
                                           <a onClick={()=>ClickUser(item)}>   
                                            <div className="article-users">
                                                <div className="article-name">{item.username}</div>
                                                <div className="article-email"> {item.email}</div>
                                            </div>
                                           </a>
                                           
                                       </li>
                                      ))
                                    }
                                  </ul>
                                 </div>   
                      
                      </div>
                      :
                      <></>
                    }
                    
                    </div>
                    
                </div>
            <div className="articles-creaate">
                
                 <div className="articles-creaate-sub">
                     <div className="users-add">
                        <p><i class="fas fa-user-plus users-add-icon"></i></p>
                        <p>Add User</p>
                     </div>
                     <div className="articles-calls">
                     <h5>Usars</h5>
                         <p>
                         <img src="https://res.cloudinary.com/codepally/image/upload/v1625922442/ridora%20website/Group_12794_nghgx3.svg" />
                         </p>
                         <div>
                         <h1><b>100</b></h1>
                         <p> User</p>
                         </div>
                     </div>
                     <div className="articles-calls">
                     <h5>Students</h5>
                         <p>
                         <img src="https://res.cloudinary.com/codepally/image/upload/v1625922442/ridora%20website/Group_12794_nghgx3.svg" />
                         </p>
                         <div>
                         <h1><b>40</b></h1>
                         <p> User</p>
                         </div>
                    
                     </div>
                     <div className="articles-calls">
                       <h5> Instructor</h5>
                         <p>
                         <img src="https://res.cloudinary.com/codepally/image/upload/v1625922442/ridora%20website/Group_12794_nghgx3.svg" />
                         </p>
                         <div>
                         <h1><b>20</b></h1>
                         <p> User</p>
                         </div>
                    
                     </div>
                    
                     
                 </div>
 
            </div>
            <div className="users-lists">
               
                <div className="users-list-cols">
                  <div className="users-list-sub">
                    <div className="users-Name">
                     <b>Name  </b> 
                    </div>
                    {/* <div className="users-Age">
                      <b>Age </b>
                    </div> */}
                    <div className="users-Gender">
                       <b>Gender</b>
                    </div>
                    <div className="users-School">
                      <b>School</b> 
                    </div>
                   
                    <div className="users-Phone">
                      <b>Phone Number </b>
                    </div>
                    <div className="users-e-Mail ">
                      <b> e-Mail Address </b>
                    </div>
                    {/* <div className="users-Gender ">
                       <b> Status </b>
                    </div> */}
                 </div>
                 {
                users.map(item => (
                    <div className="users-list-cols-sub">
                        <div className="users-Name">
                         {item.username}
                        </div>
                        {/* <div className="users-Age">
                          20
                        </div> */}
                         {
                             item.sex === "male" ?
                             <div className="users-Gender">
                              Male
                             </div>
                             :
                            <div className="users-Gender">
                              Female
                             </div>
                         }
                        
                        <div className="users-School">
                        Socia media internation..
                        </div>
                        {/* <div className="users-Class">
                        Class
                        </div> */}
                        {/* <div className="users-Parent">
                        Parent Name
                        </div> */}
                        <div className="users-Phone">
                         {
                             item.phone
                         }
                        </div>
                        <div className="users-e-Mail ">
                        {item.email}
                        </div>
                        {/* <div className="users-Gender ">
                          Student
                        </div> */}
                     </div>
                    ))
                  }
                

                </div>

            </div>
             {
              ModalNew ?
                <div className="modal-page">     
                    <div id="myModal" class="modal-sub" styles={{display: 'black'}}>
                        <div class="box1">
                            <a  onClick={setSveModalClose}>
                                    <div class="modal-final">
                                        <div class="closeModal" >&#10799;</div>
                                    </div>
                                </a>   
                                <div class="robotics-white">  
                                    <div className="users-register">
                                        <h2> Add New User</h2>
                                    <div class="field is-horizontal">
                                        
                                            <div class="field-body">
                                                <div class="field">
                                                <p class="control is-expanded has-icons-left">
                                                    <input class="input" type="text" placeholder="First name"/>
                                                
                                                </p>
                                                
                                                </div>
                                                <div class="field">
                                                <p class="control is-expanded has-icons-left has-icons-right">
                                                    <input class="input is-success" type="text" placeholder="Last name" value=""/>
                                                
                                                </p>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="field is-horizontal">
                                        
                                        <div class="field-body">
                                            <div class="field">
                                            <p class="control is-expanded has-icons-left">
                                                <input class="input" type="text" placeholder="Username"/>
                                                
                                            </p>
                                            
                                            </div>
                                            <div class="field">
                                            <p class="control is-expanded has-icons-left has-icons-right">
                                                <input class="input is-success" type="email" placeholder="Email" value=""/>
                                                
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field is-horizontal">
                                        
                                        <div class="field-body">
                                            <div class="field">
                                            <p class="control is-expanded has-icons-left">
                                                <input class="input" type="number" placeholder="number"/>
                                                
                                            </p>
                                            
                                            </div>
                                            <div class="field">
                                            <p class="control is-expanded has-icons-left has-icons-right">
                                                <input class="input is-success" type="email" placeholder="Adress" value=""/>
                                                
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field is-horizontal">
                                        <div class="field-body">
                                            <div class="field">
                                            <div class="control">
                                                <button class="button btn-default ">
                                                Submit
                                                </button>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div> 
                    </div>
                :
                <></>
            }
            
 
        </div>
    )
 }
 
 
 export default withRouter(Users)