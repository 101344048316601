import React, {useState,useEffect} from 'react';
import axios from 'axios';
import './LoginForm.css';
import { withRouter ,Redirect} from "react-router-dom";
import  AuthService  from "../../api/authentication"
import styled from "styled-components";
import {API_BASE_URL,URL_EXAM_END, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';

const Auth = new AuthService();

function LoginForm(props) {
    const [email , setEmail ] = useState(null)
  const [password , setPassword ] = useState(null)
  const [loading, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [errormessage, setErrormessage] = useState(null)
   const Url = "https://ridora.herokuapp.com/"


useEffect(()=> {
    props.error404(false)

    },[])

    
    const onLogIn = () => {
        // console.log("data-------------judge-->",email ,password)
        setIsLoaded(true)
            
            const login ={
                username : email,
                password :password,
                "strategy":"local"
             }
          const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(login),
          };
            fetch(URL_EXAM_END+"/api/login", requestOptions)
            .then(res => res.json())
            .then((res)=>{
             console.log("res", res)
                if(res.status === "error"){
                    // setIsLoaded(false)
                    setError(true)
                    setIsLoaded(false)
                    setErrormessage("Invalid Email or Password")
                    timeout()
                }else if(res.status === "true"){
                    Auth.setToken(res.token) ;
                    const requestOptions = {
                      method: 'GET',
                      headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' , 
                      'Authorization':'Bearer '  +Auth.getToken()
                     },
                    }
                    return fetch(URL_EXAM_END+"/user/"+ Auth.getProfile().id, requestOptions )
                    .then(res1 => res1.json())
                    .then(res1 => {
                      
                        // console.log("data-------------judge-->", res1)
                        props.GlobalUser(res1);
                        
                        // props.ErrorCallback(true)
                        // props.AuthCallback (true)
                        setIsLoaded(false)
                        // props.history.push('/articles');
                        // props.showError(null)
                        // redirectToHome();
                        
                       
                        if(res1.status === "true"){
                            // console.log("data--------------->yes", res1)
                              const user = res1.data;
        
                              }else{
        
                        }               
                        
                        return res;
                    }).catch(err => err);
        
                }
              
            }).catch( (error) => {
                setIsLoaded(false)
                //  console.log("errro", error);
                //  console.log("error load", error )
             });
    }
    const redirectToHome = () => {
        props.updateTitle('articles')
        props.history.push('/articles');
    }
    const redirectToRegister = () => {
        props.history.push('/register'); 
        props.updateTitle('Register');
    }


   const timeout=() =>{
        setTimeout(function() {
            setError(false)
            setErrormessage(null)
        //   console.log(this.state.noticeshow);
        }.bind(this), 4000);
    }

    return(
        <main class="main  absolute top-0 main-body" >
        {
           Auth.loggedIn()?
                 <Redirect push to="dashboard" />
               :
             
                <div class="login-body">
                    <div className="login-info"> 
                      <p> <img src="https://res.cloudinary.com/codepally/image/upload/v1626645776/Ridora/png/unnamed_1_tvmbac.png" /></p>
                      <h1>Building the future of your children at a young age.</h1>
                    </div>
                    <div className="login-input">
                    <div className="login-from">
                        <p>
                            <img src="https://res.cloudinary.com/codepally/image/upload/v1622890948/ridora%20website/ridora_logo_dlzom7.svg"/>
                        </p>
                        <h3>Login </h3>
                    </div>    
                        <div class="tabcontent mt-5 " >
                            <div className="input-info-holder  input-info-width">
                                <div style={{marginBottom:"5%"}}>
                                 <input type="text" placeholder="Email" class="input mt-1 block w-full info-text py-2 px-2  bg-white  focus:outline-none"
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                 />
                                </div>

                                <div style={{marginBottom:"5%"}}>
                                 <input type="password" placeholder="Paaword" class="input mt-1 block w-full info-text py-2 px-2  bg-white  focus:outline-none"
                                  value={password}
                                  onChange={(e)=>setPassword(e.target.value)}
                                 />
                                </div>
                                    {
                                        error ?
                                            <p style={{textAlign:"center",marginBottom:"5px",marginTop:"4%",color:"red"}}>{errormessage}</p>
                                        :
                                            <></>
                                    }

                                    {
                                            loading ?
                                          <p>  <button class="button is-loading" >Loading</button></p>
                                            :
                                           <p> <button class="button is-info is-rounded"
                                            onClick={onLogIn}
                                                    >
                                                    SIGN IN
                                                </button>
                                           </p>     
                                        }

                                {/* <p>
                                <button class="button is-info">Submit</button>
                                </p> */}
                             </div>
                        </div>
                    </div>
                </div>
               }
             </main>    
    
       
    )
}

export default withRouter(LoginForm);

const Wrapper = styled.div`
  height: inherit;
  .content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .wrapper {
      width: 100%;
      max-width: 500px;
      background-color: #fff;
      padding: 2rem;
      line-height: 1.625rem;
    }
  }
`;
