import React, { useState, useEffect } from 'react';

function Notfound(props) {
    const [modalDisplay, toggleDisplay] = useState('none');
    
    
    return(
        <div 
            className={"alert alert-danger alert-dismissable mt-4"} 
            role="alert" 
            id="alertPopUp"
            // style={{ display: modalDisplay }}
        >
            <div className="" style={{textAlign:"center",fontSize:"300%",marginTop:"5%"}} >

                <p> <img src="https://res.cloudinary.com/codepally/image/upload/v1622212168/ridora%20website/rocket_1_etyfdr.svg"/> </p>
                 <p><b>Page not found</b></p>
                 <p> <a  href="/" class="button btn-default ">
                      Back to home page
                    </a>
                </p>
            </div>
            
        </div>
    )
} 

export default Notfound