import React, {useState,useEffect} from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer' 

import {API_BASE_URL,URL_EXAM_END, API_BASE_LOCAL,ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import "./course.css";

const Course = (props) => {
    const [current, setCurrent] = useState(1)
    const [categories, setCategories] = useState(null)
    const [loaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)

    const id = props.match.params;
 

    useEffect(()=> {
        props.error404(false)
        props.updateTitle('course')
        props.error404(false)
        // console.log("data ------article----->",id)
        const fetchData = async () => {
            fetch(URL_EXAM_END+"/single/courses/"+id.id)
            .then(res => res.json())
            .then(
              (res) => {
                  if(res.status === false){
                    setIsLoaded(false);
                  }else{
                    // console.log("data ------article----->",res)
                    // console.log("data ------article----->",res.data)
                    setIsLoaded(false);
                    setCategories(res.data);
                  }

              },
              (error) => {
                setIsLoaded(false);
                setError(error);
              }
         )
      }  
        fetchData();
    },[])


    const clickSubmit =()=>{

    }

   
   
return(

        <div className="dashboard-body">
           <div className="sidenav">
                <div className="sidenav-name-sub">
                  <p>p</p>
                </div>

                <h3>Oluch ojo</h3>
                <h5>Current Class: JSS 2</h5>

                <div className="dashboard-list">
                      <div className="dashboard-list-sub">
                          <i class="fas fa-snowboarding"></i>
                                <p>Activities</p>
                      </div>
                      <div className="dashboard-list-sub">
                      <i class="fas fa-chalkboard-teacher"></i>
                                <p>Course</p>
                      </div>
                      <div className="dashboard-list-sub">
                      <i class="fas fa-cog"></i>
                                <p>Profile</p>
                      </div>
                      
                 </div>
           </div>

           <div className="dashboard-main">
                <div className="dashboard-title">
                    {
                        categories === null ?
                         <> </>
                         :
                         <div className="dashboard-title-title" style={{marginBottom:"2%"}}>
                            <img src={categories.photo} />
                            <h5>{categories.name}</h5>
                            <h6>Modules</h6>
                        </div>
                      
                    }
                 
               </div>
               <div  className="course-details">
                   {
                       categories === null ?
                       <></>
                       :
                       <ul id="progress">

                          {
                                    categories.courses.map(item => {
                                        return(
                                            <li>
                                                <div class="node green">
                                                    <i class="fas fa-check"></i>
                                                    
                                                </div>
                                                    <div  className="course-butom green">Completed</div>
                                                    <h5> Module 1</h5> 
                                                    <p>The First Thing!</p>
                                                    
                                            </li>
                                        )
                                    })
                                }
                                
                                <li><div class="divider grey"></div></li>
                                <li>
                                    <div class="node grey">
                                
                                        <img src=" https://res.cloudinary.com/codepally/image/upload/v1628356858/ridora%20website/Group_12827_gg1r43.svg"  />
                                    </div>
                                    <div  className="course-butom grey ">Start Exam</div>
                                    <h5> Module 1</h5> 
                                    <p>The First Thing!</p>
                                    
                                </li>
                                <li><div class="divider grey"></div></li>
                                <li>
                                    <div class="node grey">
                                    
                                        <img src=" https://res.cloudinary.com/codepally/image/upload/v1628356858/ridora%20website/Group_12827_gg1r43.svg"  />
                                    </div>
                                    <div  className="course-butom grey ">Start Exam</div>
                                    <h5> Module 1</h5> 
                                    <p>The First Thing!</p>
                                    
                                </li>
                        </ul>

                     }
                  

               </div>
              
               
              
              

           </div>
            
        </div>
 
    )
  
}


export default Course;