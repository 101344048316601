import React ,{useState}from 'react';
import { withRouter ,Redirect} from "react-router-dom";
import { ACCESS_TOKEN_NAME } from '../../constants/apiConstants';
import  AuthService  from "../../api/authentication"
const Auth = new AuthService();
function Header(props) {
    const [drop,setDrop] = useState(false)
    const [side, setSide] = useState(false)

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }


    let title = capitalize(props.location.pathname.substring(1,props.location.pathname.length))

    if(props.location.pathname === '/') {
        title = 'Welcome'
    }


    function renderLogout() {
        if(props.location.pathname === '/home'){
            return(
                <div className="ml-auto">
                    <button className="btn btn-danger" onClick={() => handleLogout()}>Logout</button>
                </div>
            )
        }
    }


    function handleLogout() {
        Auth.logout()
    }



   const SendNav=()=>{
        setSide(!side)
        props.sideNav(!side)
    }



    return(
        <nav class="nav-bar flex flex-wrap items-center justify-between px-7 py-2 lg:px-16">
            <div class="flex flex-no-shrink items-center mr-6 py-3 px-2 text-grey-darkest image-view">
               <img src="https://res.cloudinary.com/codepally/image/upload/v1622890948/ridora%20website/ridora_logo_dlzom7.svg" alt="" width="112" height="28" />
            </div>

            {/* <div class="navbar-brand">
                <a class="navbar-item" href="/">
                <img src="https://res.cloudinary.com/codepally/image/upload/v1622890948/ridora%20website/ridora_logo_dlzom7.svg" alt="" width="112" height="28" />
                
                </a>

                <a role="button" onClick={()=>SendNav()} class={side?"navbar-burger is-active":"navbar-burger "} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                </a>
            </div> */}

            {/* <div id="navbarBasicExample" class="navbar-menu">
             {
                    Auth.loggedIn() ?
                    <div className="mide-link-custom">
                    <ul>
                        <li>
                            <a href="/users" style={{color:props.title === "users"? "#E6BF52":""}}> 
                                User <img src="https://res.cloudinary.com/codepally/image/upload/v1625922442/ridora%20website/Group_12794_nghgx3.svg "/>
                            </a>
                        
                        </li>
                        <li>
                            <a style={{color:props.title === "courses"? "#E6BF52":""}}> 
                            Courses <img src="https://res.cloudinary.com/codepally/image/upload/v1625922442/ridora%20website/Group_12793_pk84ny.svg "/>
                            </a>
                        </li>
                        <li>
                            <a  style={{color:props.title === "schools"? "#E6BF52":""}}> 
                            Schools <img src="https://res.cloudinary.com/codepally/image/upload/v1625922442/ridora%20website/Group_sd5gqz.svg "/>
                            </a>
                        </li>
                        <li>
                        <a href="/articles" style={{color:props.title === "articles"? "#E6BF52":""}}> 
                            Blog <img src="https://res.cloudinary.com/codepally/image/upload/v1625922442/ridora%20website/Group_12793_pk84ny.svg "/>
                        </a>   
                        </li>
                    </ul>
                </div>
                :
                <></>
            }
     
                <div class="navbar-end">
                    {
                      Auth.loggedIn() ?
                         <div class="navbar-item dp-icon-body">
                         
                                {
                                    props.user === null?
                                    <b></b>
                                    :
                                    <b>
                                     <a onClick={()=>setDrop(!drop)}>{ props.user.username}</a>
                                     </b>
                                }
                          <a onClick={()=>setDrop(!drop)}>
                                <div className="dp-icon">
                                <i class="fas fa-user"></i>
                                </div>
                             </a>
                            
                            {
                              drop ?
                               <div className="header-drop">
                                  <ul>
                                      <li>Profile</li>
                                      <li><a href="/setting">Setting</a></li>
                                      <li><a href="/login" onClick={handleLogout}>Login out</a></li>
                                   </ul>
                                </div> 
                               :
                               <></> 
                            }
                              
                        </div>
                      :
                      <div class="navbar-item">
                            <div class="buttons">
                            <a class="button is-primary"   href="/register">
                                <strong>Sign up</strong>
                            </a>
                            <a class="button is-light" href="/login" >
                                Log in
                            </a>
                            </div>
                     </div>
                    }
                   
                </div>
            </div> */}
            <ul class="menu border-b md:border-none flex justify-end items-center list-reset m-0 w-full md:w-auto web-hide">

                {/* <li class="font-10 sec-black font-medium">
                    <a href="/" class="block md:inline-block uppercase px-2 lg:px-3 py-3 hover:no-underline hover:text-current">how
                        it works</a>
                </li> */}

                <li class="font-10 sec-black font-medium">
                    <a href="https://ridorastem.com/about" target="_blank" class="block md:inline-block uppercase px-2 lg:px-3 py-3 hover:no-underline hover:text-current">about
                        us</a>
                </li>

                <li class="text-xs ml-2">
                    <a href="/verification-code" class="signup-btn focus:outline-none font-semibold font-10 flex items-center text-center text-white px-4 sign">Take a Quiz
                   </a>
                </li>

                <li class="text-xs">
                    <a href="/login" type="button" class="login-btn primary-red focus:outline-none py-3 font-semibold font-10 flex items-center text-white px-4 sign">LOGIN</a>
                </li>
            </ul>
      </nav>
       
    )
}
export default withRouter(Header);