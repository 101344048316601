import React, {useState,useEffect} from 'react';
import Question from './Question';
import { CountdownCircleTimer } from 'react-countdown-circle-timer' 
import {API_BASE_URL,URL_EXAM_END, API_BASE_LOCAL,ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import "./code.css";

const Code= (props) => {
    const [current, setCurrent] = useState(1)
    const [codeValue ,setCodeValue] = useState(null)
    const [loading ,setLoading] = useState(false)
    const history = useHistory();

    useEffect(()=> {
        props.error404(false)
       
    },[])


    const clickSubmit =()=>{
        setLoading(true)
        const data ={
           
              id : codeValue,
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            };
            fetch(URL_EXAM_END+"/exam/"+codeValue, requestOptions)
            .then(res => res.json())
            .then((res)=>{
                console.log("res", res)
                  if(res.status === false){
                    setLoading(false)
                    toast.warn("Sorry wrong code", { theme: "light" });
                    // toast("Sorry wrong code")
                    }else {
                        setLoading(false)
                      history.push("/exam-place/"+codeValue, { data:data})
                  }
                //   history.push("/result", { data:data})
            })
        }

   
   
return(

        <div className="code-body">
               {/* <div class="error-message"> */}
               <ToastContainer class="error-message" toastClassName={"error-message"}
               style={{marginTop:"4%"}}/>
               {/* </div> */}

            <div className="code-sub">
                <h2>Verification</h2>
                <h5>Before you can proceed to take Quiz Please verify your membership</h5>

                <div style={{width:"84%",margin:"auto",marginTop:"2%"}}>
                    <input type="text" placeholder="Enter your Examination ID" class="input mt-1 block w-full info-text py-2 px-2  bg-white  focus:outline-none"
                    value={codeValue}
                    onChange={(e)=>setCodeValue(e.target.value)}
                    />
                </div>

                <div className="code-buttom">
                {/* <a class="button code-buttom-close" href={"/"}>Cancel</a> */}
                {
                  loading ?
                  <button class="button is-loading" >Loading</button>
                  :
                  <button class="button code-buttom-default" onClick={clickSubmit}>Continue</button>
                }
               
                </div>    

            </div>
            
        </div>
 
    )
  
}


export default Code;