import React, {useState,useEffect} from 'react';
import axios from 'axios';
// import './LoginForm.css';
import {API_BASE_URL, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import { withRouter ,Redirect} from "react-router-dom";
import  AuthService  from "../../api/authentication"
import styled from "styled-components";
const Auth = new AuthService();

function Login(props) {
    const [email , setEmail ] = useState(null)
  const [password , setPassword ] = useState(null)
  const [loading, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [errormessage, setErrormessage] = useState(null)
   const Url = "https://ridora.herokuapp.com/"
//   useEffect(async () => {
//     // props.error404(false)
    
// }, [])


useEffect(()=> {
    // props.error404(false)

    },)

    
    const onLogIn = () => {
        // console.log("data-------------judge-->", )
        setIsLoaded(true)
            
            const login ={
                username : email,
                password :password,
                "strategy":"local"
             }
          const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(login),
          };
            fetch(API_BASE_URL+"/api/login", requestOptions)
            .then(res => res.json())
            .then((res)=>{
             console.log("res", res)
                if(res.status === "error"){
                    // setIsLoaded(false)
                    setError(true)
                    setIsLoaded(false)
                    setErrormessage("Invalid Email or Password")
                    timeout()
                }else if(res.status === "true"){
                    Auth.setToken(res.token) ;
                    const requestOptions = {
                      method: 'GET',
                      headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' , 
                      'Authorization':'Bearer '  +Auth.getToken()
                     },
                    }
                    return fetch(API_BASE_URL+"/user/"+ Auth.getProfile().id, requestOptions )
                    .then(res1 => res1.json())
                    .then(res1 => {
                        
                        // console.log("data-------------judge-->", res1)
                        props.GlobalUser(res1);
                        // props.ErrorCallback(true)
                        // props.AuthCallback (true)
                        setIsLoaded(false)
                        // props.history.push('/articles');
                        // props.showError(null)
                        redirectToHome();
                       
                        if(res1.status === "true"){
                            // console.log("data--------------->yes", res1)
                              const user = res1.data;
        
                              }else{
        
                        }               
                        
                        return res;
                    }).catch(err => err);
        
                }
              
            }).catch( (error) => {
                setIsLoaded(false)
                //  console.log("errro", error);
                //  console.log("error load", error )
             });
    }
    const redirectToHome = () => {
        props.updateTitle('articles')
        props.history.push('/articles');
    }
    const redirectToRegister = () => {
        props.history.push('/register'); 
        props.updateTitle('Register');
    }


   const timeout=() =>{
        setTimeout(function() {
            setError(false)
            setErrormessage(null)
        //   console.log(this.state.noticeshow);
        }.bind(this), 4000);
    }

    return(

      <div>
          {
             Auth.loggedIn()?
              <Redirect push to="articles" />
               :
               <div className="login-from">

                   <p>
                       <img src="https://res.cloudinary.com/codepally/image/upload/v1622890948/ridora%20website/ridora_logo_dlzom7.svg"/>
                  </p>
                  <h2> Log in  </h2>
               <div class="card">
                   
                   <div class="card-content">
                       <div class="content">
                        <div class="field">
                           <label class="label">Email</label>
                           <div class="control">
                               <input class="input" 
                               type="email"
                               
                               name="email"
                               value={email}
                                 onChange={(e)=>setEmail(e.target.value)}
                                 placeholder="e.g. alexsmith@gmail.com"/>
                           </div>
                           </div>
   
                           <div class="field">
                           <label class="label">Password</label>
                           <div class="control">
                               <input class="input" type="password" 
                                name="password"
                                value={password}
                                onChange={(e)=>setPassword(e.target.value)}
                               placeholder="Password"/>
                           </div>
                           {
                               error ?
                                <p style={{textAlign:"center",marginBottom:"5px",marginTop:"4%",color:"red"}}>{errormessage}</p>
                               :
                                <></>
                           }

                           </div>
                           <div className="login-bottom">
                            {
                                loading ?
                                <button class="button is-loading" >Loading</button>
                                :
                                <button class="button is-info is-rounded"
                                onClick={onLogIn}
                                        >
                                        SIGN IN
                                    </button>
                            }
                           </div>

                       </div>
                     </div>
                   </div>
               </div>
             } 
      </div>
       
    )
}

export default withRouter(Login);

const Wrapper = styled.div`
  height: inherit;
  .content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .wrapper {
      width: 100%;
      max-width: 500px;
      background-color: #fff;
      padding: 2rem;
      line-height: 1.625rem;
    }
  }
`;
