import React ,{ useState , useEffect} from "react";
import { truncateText } from "truncate-strings";
import { useParams , withRouter } from "react-router-dom";
import TimeAgo from 'react-timeago'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {URL_EXAM_END, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';

const Categoris =(props)=>{
  
   
    const [source , setSource] = useState()
    const [categories, setCategories] = useState([])
    const [loading, setIsLoaded] = useState(false)
    const [editload, setEditload] = useState(false)
    const [error, setError] = useState()
    const [idArticle  ,setidArticle] = useState()
    const [edit  ,setEdit] = useState()
    const [value  ,setValue] = useState(null)
    const [modalCategory,setModalCategory] = useState(false)
    const [category, getCategory] = useState(null)
    const [modalDelet , setModalDelet] = useState(false)


    useEffect(async () => {
      setIsLoaded(true);
        setidArticle(uuidv4())
        props.updateTitle('categories')
        props.error404(false)
        const fetchData = async () => {
            fetch(URL_EXAM_END+"/categories")
            .then(res => res.json())
            .then(
              (result) => {
                  // console.log("data ------article----->",result)
                setIsLoaded(false);
                setCategories(result.data);
              },
              (error) => {
                setIsLoaded(false);
                setError(error);
              }
         )
      }  
        fetchData();
   }, [])
 


const  addCateogory=()=>{
      console.log("loling to user")
  }

 const addModal = () =>{
    setModalCategory(!modalCategory)
 }

const EditShow=(data)=>{
    getCategory(data)
    setValue(data.name)
    // console.log("data=----------->",data)
    setEdit(true)
   
 }

 const CloseModal = ()=>{
    getCategory(null)
    setModalCategory(false)
    // console.log("data=----------->",data)
    setEdit(false)
    setModalDelet(false)
 }

 const updateEdit = () =>{
    setEditload(true)
    //    console.log("lol------------------>",category ,value)
       const data ={
        name :value,
       }   
     const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    };
    console.log("requestOptions",requestOptions)
    fetch(URL_EXAM_END+`/categories/${category._id}`, requestOptions)
    .then(res => res.json())
    .then((res)=>{
    console.log("res", res)
        if(res.status === false){
            toast("Update successful ")
            setEditload(false)
        }else {
            toast("Update not successful ")
            setEditload(false)
            // setPublishLoader(false)
        }
    }) .catch((error) => {
        console.error('Error:', error);
    });
 }


 const DeteleShow= ( data)=>{
    setModalDelet(true)
    getCategory(data)
    setValue(data.name)
 }

 const deleteData=()=>{
    setEditload(true)
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify(data),
    };
    console.log("requestOptions",requestOptions)
    fetch(URL_EXAM_END+`/categories/${category._id}`, requestOptions)
    .then(res => res.json())
    .then((res)=>{
    console.log("res", res)
        if(res.status === false){
            setEditload(false)
            toast("Delete not successful ")
        }else {
            setCategories(categories.filter(item => item._id !== category._id));
            setEditload(false)
            setModalDelet(false)
            toast("Delete successful ")
            // setPublishLoader(false)
        }
    }) .catch((error) => {
        console.error('Error:', error);
    });
  }



const CreatData =()=>{
    setEditload(true)
     const data ={
            name :value,
        }   
        // console.log("value=--------->",value)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        };
        console.log("requestOptions",requestOptions)
        fetch(URL_EXAM_END+`/categories/new`, requestOptions)
        .then(res => res.json())
        .then((res)=>{
        console.log("res", res)
            if(res.status === false){
                setEditload(false)
                toast("Create not successful ")
            }else {
                setCategories([...categories, res.data])
                setModalCategory(false)
                toast("Create successful ")
                // setCategories(categories.push(res.data));
                setEditload(false)
                // setPublishLoader(false)
            }
        }) .catch((error) => {
            console.error('Error:', error);
        });
}




   const uuidv4=()=> {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  

   return(
       <div className="categoris">
           <ToastContainer />
           <a class="button" onClick={addModal}><i class="fas fa-plus"></i></a> 
            <h2> Categories</h2>    
             <div className="categoris">
               
               <div className="catgoris-list-cols">
                 <div className="categories-list-sub">
                   <div className="categories-Name">
                     <b> Name Categories  </b> 
                   </div>
                   <div className="categories-status">
                      {/* <b>Status </b> */}
                   </div>
                </div>
                {
               categories.map(item => (
                   <div className="users-list-cols-sub">
                       <div className="categories-Name">
                          {item.name}
                       </div>
                       <div className="categories-status">
                       <a class="button is-small is-info is-outlined" onClick={()=>EditShow(item)} style={{marginRight:"1%"}}><i class="fas fa-edit"></i></a> 
                       <a class="button is-small is-danger is-outlined" onClick={()=>DeteleShow(item)}  style={{marginRight:"1%"}}><i class="fas fa-trash-alt"></i></a> 
                       </div>
                    </div>
                   ))
                 }
               </div>
            </div>
            {
              edit?
                <div className="modal-page">     
                    <div id="myModal" class="modal-sub" styles={{display: 'black'}}>
                        <div class="box1">
                            <a  onClick={CloseModal}>
                                    <div class="modal-final">
                                        <div class="closeModal" >&#10799;</div>
                                    </div>
                                </a>   
                                <div class="robotics-white">  
                                    <div className="users-register">
                                    {
                                      category === null?
                                      <h2>  Categories</h2>
                                      :
                                      <h2> Edit Categories {category.name}</h2> 
                                    }
                                        
                                    
                                    
                                    <div class="field is-horizontal">
                                        
                                        <div class="field-body">
                                            <div class="field">
                                            <p class="control is-expanded has-icons-left">
                                                <input class="input" type="text" placeholder="number"  onChange={e=>setValue(e.target.value)} value={category.name}/>
                                                
                                            </p>
                                            
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div class="field is-horizontal">
                                        <div class="field-body">
                                            <div class="field">
                                            <div class="control">
                                                {
                                                    editload ?
                                                    <p style={{textAlign:"center"}}>
                                                       <button class="button is-white   is-loading" >Loading</button>
                                                    </p>
                                                    :
                                                    <p style={{textAlign:"center"}}> 
                                                        <button class="button btn-default" onClick={updateEdit}>
                                                        Submit
                                                        </button>
                                                    </p> 
                                                }
                                                
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div> 
                    </div>
                :
                <></>
            }
            {
              modalDelet?
                <div className="modal-page">     
                    <div id="myModal" class="modal-sub" styles={{display: 'black'}}>
                        <div class="box1">
                            <a  onClick={CloseModal}>
                                    <div class="modal-final">
                                        <div class="closeModal" >&#10799;</div>
                                    </div>
                                </a>   
                                <div class="robotics-white">  
                                    <div className="users-register">
                                        <h2>Are you sure you want to delet this data</h2>
                                    <div class="field is-horizontal">
                                        <div class="field-body">
                                            <div class="field">
                                            <div class="control">
                                            {
                                                editload ?
                                                <p style={{textAlign:"center"}}>
                                                    <button class="button is-white   is-loading" >Loading</button>
                                                </p>
                                                :
                                                <p style={{textAlign:"center"}}>
                                                    <button class="button btn-default " onClick={(e)=>deleteData()}>
                                                    Submit
                                                    </button>
                                                </p> 
                                             } 
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div> 
                    </div>
                :
                <></>
            }
            {
              modalCategory ?
                <div className="modal-page">     
                    <div id="myModal" class="modal-sub" styles={{display: 'black'}}>
                        <div class="box1">
                            <a  onClick={CloseModal}>
                                    <div class="modal-final">
                                        <div class="closeModal" >&#10799;</div>
                                    </div>
                                </a>   
                                <div class="robotics-white">  
                                    <div className="users-register">
                                        <h2> Add Categories</h2>
                                    
                                    
                                    <div class="field is-horizontal">
                                        
                                        <div class="field-body">
                                            <div class="field">
                                            <p class="control is-expanded has-icons-left">
                                            <input class="input" type="text" placeholder="number"  onChange={e=>setValue(e.target.value)} value={value}/>
                                            </p>
                                            
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div class="field is-horizontal">
                                        <div class="field-body">
                                            <div class="field">
                                            <div class="control">
                                            {
                                                editload ?
                                                <p style={{textAlign:"center"}}>
                                                    <button class="button is-white   is-loading" >Loading</button>
                                                </p>
                                                :
                                                <p style={{textAlign:"center"}}>
                                                    <button class="button btn-default " onClick={CreatData}>
                                                    Submit
                                                    </button>
                                                </p> 
                                              } 
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div> 
                    </div>
                :
                <></>
            }
         </div>
   )
}


export default withRouter(Categoris)