import React, {useState,useEffect} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  
  Redirect
} from "react-router-dom";
import './App.css';
import Header from './components/Header/Header';
import LoginForm from './components/LoginForm/LoginForm';
import RegistrationForm from './components/RegistrationForm/RegistrationForm';
import Home from './components/Home/Home';
import AlertComponent from './components/AlertComponent/AlertComponent';  
import { PrivateRoute } from './utils/PrivateRoute';
import  AuthService  from "./api/authentication"
// import Article from "./page/article/article"
// import  NewArticlePage from "./page/article/newArticle"
import Users from "./page/users/user"
import Notfound from "./page/notfound/404"
import Categoris from "./page/category/categoris"
import Setting from "./page/users/setting"
import Step from "./page/users/step"
import Loader from "./page/loading"
import Login from "./page/loginHome/loginHome"
import Onboarding from "./page/onboarding/onboarding"
import Test from "./page/quiz/text"
import Exam  from "./page/exam/exam"
import Games  from "./page/exam/finish"
import Result from "./page/quiz/result"
import Code from "./page/quiz/code"
import Dashboard from "./page/dashboard/dashboard"
import Course from "./page/course/course.js"



import { createBrowserHistory } from "history";
// import { useHistory } from "react-router-dom";
import {API_BASE_URL, ACCESS_TOKEN_NAME} from './constants/apiConstants';


const Auth = new AuthService();



function App(props) {
  // let history = useHistory();
  
  const [title, updateTitle] = useState(null);
  const [errorMessage, updateErrorMessage] = useState(null);
  const [glo, setGlo ] = useState(null);
  const [ IsLoaded , setIsLoaded] = useState()
  const [ error , setError] = useState(false)
  const [ Authenticated , setAuthenticated] = useState(false)
  const [ ErrorAuth , setErrorAuth] = useState(false)
  const [pageLoading, setpageLoading] = useState(true)
   const [errorPage , setErrorPage] = useState(true)
   const [sidevan , setSidevan] = useState(false)


   


  useEffect(async () => {
    // getId(id)
    setpageLoading(true)
    const fetchData = async () => {
      if(Auth.loggedIn()){
        if (Authenticated || !ErrorAuth){
          const requestOptions = {
              method: 'GET',
              headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' , 
              'Authorization':'Bearer '  +Auth.getToken()
              }
            }
              fetch(API_BASE_URL+"/user/"+Auth.getProfile().id,  requestOptions)
              .then(res => res.json())
              .then(
                (res) => {

                  setpageLoading(false)
                  setIsLoaded(true);
                  setAuthenticated(true)
                  setErrorAuth(true)
                  setGlo(res)
                  // setCategories(res.data);
                },
                (error) => {
                  setpageLoading(false)
                  setAuthenticated(false)
                  setErrorAuth(true)
                  setIsLoaded(true);
                  setError(error);
                }
            )
        }else {
          setAuthenticated(false)
          setErrorAuth(false)
        }
      }else{
        
     }
     
  }  
  // login()
    fetchData();
}, [])

useEffect(async () => {

  if (Authenticated || ErrorAuth){
    setAuthenticated(true)
    setErrorAuth(true)
    // console.log("useeff----new------>",Authenticated,ErrorAuth)
  }



}, [])

const checklogout = (data)=>{
  // console.log("checklogout----new------>",data)
 
}

 const checkMoblie=(data)=>{
  setSidevan(data)
    // console.log("data props data",data)
}

const getErorr = (data)=>{
  setErrorPage(false)
  // console.log("data props =---------------->",data)
}

function handleLogout() {
  Auth.logout()
}


  
  return (
    <Router >

       <Switch>
             <div className="">
                <Header title={title} logout={checklogout} sideNav={checkMoblie}  user={glo}/>
              <div>

              <Route  exact path="/" 
                    exact render ={props =>{
                return <Code   {...props}  showError={updateErrorMessage}
                error404={getErorr} 
                // error404={setErrorPage}
                GlobalUser={setGlo} 
                updateTitle={updateTitle} 
              />
            }}/>
                  
              {
               sidevan ?
              <div className="app-side">  
                <div className="sidenav-tilte">
                     <ul>
                        <li>
                            <a href="/connect" > <i class="fas fa-home"> </i> Home</a> 
                        </li>
                        <li>
                            <a href="#" > <i class="fas fa-school icon-link"></i> School</a> 
                        </li>
                        <li>
                            <a href="/categories" ><i className="fas fa-exchange-alt icon-link"></i>Categories</a> 
                        </li>
                        <li>
                            <a href="/setting" ><i className="fas fa-cog icon-link"></i>Settings</a>
                        </li>
                        <li>
                            <a className="button is-danger"  onClick={handleLogout}><i className="fas fa-sign-out-alt"></i>Logout</a>
                        </li>
                      </ul> 
                    </div>   
                  </div>
                 :
                  <></>
               }
              {/* <Route  exact path="/register" component={LoginForm} error404={setErrorPage} errorMessage={errorMessage} hideError={updateErrorMessage}/> */}


               <Route path="/login" exact render ={props =>{
                  return <LoginForm   {...props}  showError={updateErrorMessage}
                  error404={setErrorPage}
                  GlobalUser={setGlo} 
                  updateTitle={updateTitle} 
                />
              }}/>


            <Route path="/register" exact render ={props =>{
                  return <RegistrationForm   {...props}  showError={updateErrorMessage}
                  error404={getErorr}
                  GlobalUser={setGlo} 
                  updateTitle={updateTitle} 
                />
              }}/>



            <Route path="/quiz" exact render ={props =>{
                  return <Test   {...props}  showError={updateErrorMessage}
                  error404={getErorr}
                  GlobalUser={setGlo} 
                  updateTitle={updateTitle} 
                />
              }}/>


           


             <Route path="/result" exact render ={props =>{
                  return <Result  {...props}  showError={updateErrorMessage}
                  error404={getErorr}
                  GlobalUser={setGlo} 
                  updateTitle={updateTitle} 

                />
              }}/>

             <Route path="/exam-place/:id" exact render ={props =>{
                  return <Exam   {...props}  showError={updateErrorMessage}
                  error404={getErorr}
                  GlobalUser={setGlo} 
                  updateTitle={updateTitle} 
                />
              }}/>



             <Route path="/time-out" exact render ={props =>{
                  return <Games  {...props}  showError={updateErrorMessage}
                  error404={getErorr}
                  GlobalUser={setGlo} 
                  updateTitle={updateTitle} 
                />
              }}/>
  
              
              <Route path="/verification-code" exact render ={props =>{
                  return <Code  {...props}  showError={updateErrorMessage}
                  error404={getErorr}
                  GlobalUser={setGlo} 
                  updateTitle={updateTitle} 
                />
              }}/>

               <Route path="/dashboard" exact render ={props =>{
                  return <Dashboard  {...props}  showError={updateErrorMessage}
                  error404={getErorr}
                  GlobalUser={setGlo} 
                  updateTitle={updateTitle} 
                />
              }}/>


            <Route path="/onboarding" exact render ={props =>{
                  return <Onboarding  {...props}  showError={updateErrorMessage}
                  error404={getErorr}
                  GlobalUser={setGlo} 
                  updateTitle={updateTitle} 
                />
              }}/>



              <Route path="/courses/:id" exact render ={props =>{
                  return <Course  {...props}  showError={updateErrorMessage}
                  error404={getErorr}
                  GlobalUser={setGlo} 
                  updateTitle={updateTitle} 
                />
              }}/>
   

            
               
              <PrivateRoute  path="/update" error404={setErrorPage} component={Step} GlobalUser={setGlo} showError={updateErrorMessage} updateTitle={updateTitle} user={glo}  />
             <PrivateRoute  path="/setting" error404={setErrorPage} component={Setting} GlobalUser={setGlo} showError={updateErrorMessage} updateTitle={updateTitle} user={glo}  />
             <PrivateRoute  path="/categories" error404={setErrorPage} component={Categoris} GlobalUser={setGlo} showError={updateErrorMessage} updateTitle={updateTitle} user={glo}  />
             <PrivateRoute  path="/users" error404={setErrorPage} component={Users} GlobalUser={setGlo} showError={updateErrorMessage} updateTitle={updateTitle} user={glo}  />
             {/* <PrivateRoute exact path="/articles"  error404={setErrorPage} component={Article} GlobalUser={setGlo} 
               showError={updateErrorMessage} updateTitle={updateTitle}  user={glo}/> */}
             {/* <PrivateRoute  path="/articles/d/:id/edit"  error404={setErrorPage} component={NewArticlePage} GlobalUser={setGlo} showError={updateErrorMessage} updateTitle={updateTitle} user={glo}  /> */}
          
            {
             errorPage ?
              <Route  exact path="*" component={Notfound} errorMessage={errorMessage} hideError={updateErrorMessage}/>
              :
              <div></div>
             }
            
          </div>

    </div>
    </Switch>
    </Router>
  );
}

export default App;
