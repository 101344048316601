import React, {useState,useEffect} from 'react';
// import Question from './Question';
import { CountdownCircleTimer } from 'react-countdown-circle-timer' 
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import {API_BASE_URL,URL_EXAM_END, API_BASE_LOCAL,ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import { useHistory } from "react-router-dom";

import "./result.css";

const Games = (props) => {
    const history = useHistory();
    const [current, setCurrent] = useState(1)
    // const [score, setScore] = useState(props.location.state.data. score)
    // const [questions, setQuestions] = useState(props.location.state.data.questions)
    // const [Leader ,setLeader]= useState()
    // const [score ,setScore] = useState()
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const [reviewsTab , setReviewsTab] =useState(true)
    const [statistTab , setStatistTab] =useState(false)
    const [leaderTab , setLeaderTab] =useState(false)
    const [message, setMessage] =useState(null)
    const [percent, setPercent]=useState(null)



    useEffect(()=> {
        props.error404(false)
        // let percent1 = (props.location.state.data.score / props.location.state.data.questions.length * 100);
        // const requestOptions = {
        //     method: 'GET',
        //     headers: { 'Content-Type': 'application/json' },
        //     // body: JSON.stringify(login),
        // };
        if(props.location.state.data === undefined || props.location.state.data === null){
            history.push("/verification-code/")
        }else{
            let percent1 = (props.location.state.data.score / props.location.state.data.questions.length * 100);
            const data = {
                total:  percent1,
                correct:props.location.state.data.score
            }
       
          const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data),
          };
            // console.log("data====--------------->",props.location.state.data.codeId.codeId,  props.location.state.data)
            fetch(URL_EXAM_END+"/finish-exam/"+props.location.state.data.codeId.codeId,requestOptions)
            .then(res => res.json())
            .then(
              (res) => {
                //   console.log("res ---==============---> yes ", res)
                if(res.status === true ){
                
                }
           
                },
                (error) => {
                
              }
         )
        }
       

   
    },[])


    // const Leader = () =>{
    //     setLeaderTab(true)
    //     setReviewsTab(false)
    //     setStatistTab(false)
    // }

    // const Reviews = ()=>{
    //     setLeaderTab(false)
    //     setReviewsTab(true)
    //     setStatistTab(false)
    // }

    // const Statist = ()=>{
    //     setLeaderTab(false)
    //     setReviewsTab(false)
    //     setStatistTab(false)
    // }
 

   
return(

        <div>
            <div className="result-done-full">
                <div className="result-finish-done">
                    <div className="result-finish-img">
                        <img src="https://res.cloudinary.com/codepally/image/upload/v1629123010/ridora%20website/Group_12900_vpxjiz.svg"/>
                    </div>
                   
                    <h4>Nice! awesome job</h4>
                    <p>
                    Congratulations for completing
                        The Robotics Module!
                        You’ve unlocked the next Module
                    </p>
                    <div style={{textAlign:"center"}}>
                       <p><a href="/verification-code" style={{width:"50%",marginTop:"5%",textAlign:"center",color:"#fff"}} class="button code-buttom-default" >Done</a></p>
                    </div>
                </div>
            </div>
        </div>
 
    )
  
}


export default Games;