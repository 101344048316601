export default {
    quiz :{
     teacher:"chichi gift",
     class : "Science & Nature",
     Curriculum : "Science & Nature",
     question : [
  
             {
                 id: 1,
                 "category":"Science & Nature","type":"multiple","difficulty":"easy",question:"Which Apollo mission was the first one to land on the Moon?", 
                 choices: [
                     {
                     id: 'a',
                     text: 'Apollo 10'
                     },
                     {
                     id: 'b',
                     text: 'Apollo 9'
                     },
                     {
                     id: 'c',
                     text: 'Apollo 13'
                     },
                     {
                     id: 'd',
                     text: 'Apollo 11'
                     }
                 ],
                 correct: 'd'
                 },
                 
             {
                 id:2,
                 question:"Which of the following bones is not in the leg?","correct_answer":"Radius",
                 choices: [
                     {
                     id: 'a',
                     text: 'Radius'
                     },
                     {
                     id: 'b',
                     text: 'Fibula '
                     },
                     {
                     id: 'c',
                     text: 'Tibia'
                     },
                     {
                     id: 'd',
                     text: 'Patella'
                     }
                 ],
                 correct: 'a'
             },
             {
                 id:3,
                 "question":"What is the largest animal currently on Earth?","correct_answer":"Blue Whale",
                 choices: [
                 {
                     id: 'a',
                     text: 'Colossal Squid'
                 },
                 {
                     id: 'b',
                     text: 'Giraffe'
                 },
                 {
                     id: 'c',
                     text: 'Blue Whale'
                 },
                 {
                     id: 'd',
                     text: 'Orca'
                 }
                 ],
                 correct: 'c'
                 
             },
             {
                 id:4,
                 "question":"Which element has the chemical symbol &#039;Fe&#039;?","correct_answer":"Iron",
                 choices: [
                 {
                     id: 'a',
                     text: 'Gold'
                 },
                 {
                     id: 'b',
                     text: 'Silver'
                 },
                 {
                     id: 'c',
                     text: 'Iron'
                 },
                 {
                     id: 'd',
                     text: 'Tin'
                 }
                 ],
                 correct: 'c'
                 
                 
             },
 
             {
                 id:5,
                 "question":"Rhinoplasty is a surgical procedure on what part of the human body?","correct_answer":"Nose",
                 choices: [
                 {
                     id: 'a',
                     text: 'Chin'
                 },
                 {
                     id: 'b',
                     text: 'Nose'
                 },
                 {
                     id: 'c',
                     text: 'Neck'
                 },
                 {
                     id: 'd',
                     text: 'Ears'
                 }
                 ],
                 correct: 'b'
                 
                 
             },
             {
                 id:6,
                 "question":"Which type of rock is created by intense heat AND pressure?","correct_answer":"Metamorphic",
                 choices: [
                 {
                     id: 'a',
                     text: 'Sedimentary'
                 },
                 {
                     id: 'b',
                     text: 'Igneous'
                 },
                 {
                     id: 'c',
                     text: 'Diamond'
                 },
                 {
                     id: 'd',
                     text: 'Metamorphic'
                 }
                 ],
                 correct: 'd'
                 
             },
             {
                 id:7,
                 "question":"What is the elemental symbol for mercury?","correct_answer":"Hg",
                 choices: [
                 {
                     id: 'a',
                     text: 'Me'
                 },
                 {
                     id: 'b',
                     text: 'Mc'
                 },
                 {
                     id: 'c',
                     text: 'Hg'
                 },
                 {
                     id: 'd',
                     text: 'Hy'
                 }
                 ],
                 correct: 'c'
             },
             {
                 id:8,
                 "question":"What is the first element on the periodic table?","correct_answer":"Hydrogen",
                 choices: [
                 {
                     id: 'a',
                     text: 'Hydrogen'
                 },
                 {
                     id: 'b',
                     text: 'Lithium'
                 },
                 {
                     id: 'c',
                     text: 'Oxygen'
                 },
                 {
                     id: 'd',
                     text: 'Helium'
                 }
                 ],
                 correct: 'a'
             },
             {
                 id:9,
                 "question":"How many planets make up our Solar System?","correct_answer":"8",
                 choices: [
                 {
                     id: 'a',
                     text: '5'
                 },
                 {
                     id: 'b',
                     text: '7'
                 },
                 {
                     id: 'c',
                     text: '8'
                 },
                 {
                     id: 'd',
                     text: '2'
                 }
                 ],
                 correct: 'c'
             
             },
             {
                 id:10,
                 "question":"Which of the following blood vessels carries deoxygenated blood?","correct_answer":"Pulmonary Artery",
                 choices: [
                 {
                     id: 'a',
                     text: 'Coronary Artery'
                 },
                 {
                     id: 'b',
                     text: 'Pulmonary Artery'
                 },
                 {
                     id: 'c',
                     text: 'Aorta'
                 },
                 {
                     id: 'd',
                     text: 'Pulmonary Vein'
                 }
                 ],
                 correct: 'b'
             },
             {
                 id:11,
                 "question":"What is the chemical makeup of water?","correct_answer":"H20",
                 choices: [
                 {
                     id: 'a',
                     text: 'H20'
                 },
                 {
                     id: 'b',
                     text: 'CO2'
                 },
                 {
                     id: 'c',
                     text: 'H'
                 },
                 {
                     id: 'd',
                     text: 'C12H6O2'
                 }
                 ],
                 correct: 'a'
                 
             },
             {
                 id:12,
                 "question":"What is the primary addictive substance found in tobacco?","correct_answer":"Nicotine",
                 choices: [
                 {
                     id: 'a',
                     text: '"Cathinone'
                 },
                 {
                     id: 'b',
                     text: 'Nicotine'
                 },
                 {
                     id: 'c',
                     text: 'Glaucine'
                 },
                 {
                     id: 'd',
                     text: 'Ephedrine'
                 }
                 ],
                 correct: 'b'
                 
             },
             {
                 id:13,
                 "question":"How many bones are in the human body?","correct_answer":"206",
                 choices: [
                 {
                     id: 'a',
                     text: '200'
                 },
                 {
                     id: 'b',
                     text: '206'
                 },
                 {
                     id: 'c',
                     text: '209'
                 },
                 {
                     id: 'd',
                     text: '203'
                 }
                 ],
                 correct: 'b'
                 
             },
             {
                 id:14,
                 "question":"The asteroid belt is located between which two planets?","correct_answer":"Mars and Jupiter",
                 choices: [
                 {
                     id: 'a',
                     text: 'Mercury and Venus'
                 },
                 {
                     id: 'b',
                     text: 'Jupiter and Saturn'
                 },
                 {
                     id: 'c',
                     text: 'Earth and Mars'
                 },
                 {
                     id: 'd',
                     text: 'Mars and Jupiter'
                 }
                 ],
                 correct: 'd'
             
                 
             },
             {
                 id:15,
                 "question":"Human cells typically have how many copies of each gene?","correct_answer":"2",
                 choices: [
                 {
                     id: 'a',
                     text: '1'
                 },
                 {
                     id: 'b',
                     text: '4'
                 },
                 {
                     id: 'c',
                     text: '2'
                 },
                 {
                     id: 'd',
                     text: '6'
                 }
                 ],
                 correct: 'c'
             
                 
             },
             
   
    ]
    }
 }