import React, { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import { CountdownCircleTimer } from 'react-countdown-circle-timer' 
// var question = require('./data.js').default;
import  QuestionList  from "./QuestionList.js"
import  getQuestion  from './data.js' 
import {API_BASE_URL, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';

// import './AlertComponent.css';
import "./quiz.css";

const Test = (props) => {
    const  [question ,setQuestion] = useState(getQuestion.quiz.question)    
     const [current, setCurrent]  = useState(1)
     const [exam, setExam]  = useState(false)



    useEffect(()=> {
        props.error404(false)
        props.updateTitle('categories')
      //   props.error404(false)
      //   const fetchData = async () => {
      //       fetch(API_BASE_LOCAL+"/courses")
      //       .then(res => res.json())
      //       .then(
      //         (result) => {
      //             console.log("data ------article----->",result)
      //           setIsLoaded(false);
      //           setCategories(result.data);
      //         },
      //         (error) => {
      //           setIsLoaded(false);
      //           setError(error);
      //         }
      //    )
      // }  
      //   fetchData();
    },[])

    const setScore =()=>{

    }

    const MakeCurrent = ()=>{

    }

    const ToPlay = ()=>{
      setExam(true)
    }

return(
    <>
      {
          exam ?
            <QuestionList  questions={question} current={current} setCurrent={MakeCurrent} setScore={setScore} />
          :
           <div className="quiz-body">
              <div className="quiz-head">
                 <h1>  Are you ready to take your Exam! </h1>
              </div>
              <div className="quiz-ridora-logo">
                 <img src="https://res.cloudinary.com/codepally/image/upload/v1622890948/ridora%20website/ridora_logo_dlzom7.svg"/>
              </div>
              <div className="quiz-taking">
                    <div className="course-quiz-head " >
                     <img src="https://res.cloudinary.com/codepally/image/upload/v1628339354/ridora%20website/robot_2_jigtub.svg" /> 
                        <h5>Robotics</h5>
                        <h6>Sub- Title of Course </h6>
                    </div>
                    <div className="quiz-button-to-start">
                       <button class="button is-normal quiz-button-to-back"> <i class="fas fa-arrow-left"></i> Black </button>
                       <button class="button is-normal quiz-button-to-next" onClick={ToPlay}>Start</button>
                    </div>
                    
              </div>
           </div>
      }
    
    </>
)


}

export default Test;