import React, {useState} from 'react';
import Question from './Question';
import { CountdownCircleTimer } from 'react-countdown-circle-timer' 
import { useHistory } from "react-router-dom";

import "./quiz.css";

const QuestionList = (props) => {
    const [current, setCurrent] = useState(1)
    const [getscore, setGetscore] = useState(null)
    const [score, setScore] = useState(null)
    const history = useHistory();
   

    const getScore =(data)=>{
        setGetscore(data)
        setScore(data)
        // console.log(" Question 1 -----setScore----,",  data)
    }

    const getCurrent = (data, passdata )=>{
        setCurrent(data)
        setScore(passdata)
        // console.log(" Question ----== ---current--check if it get true----,",  props.questions.length +"  love",  data)
        if (data > props.questions.length) {
            // console.log(" Question ----== --2-current--2check if it get true----," + props.questions.length +"  love",  data)
            var scorebox = '';
            var data={
              score:passdata,
              questions:props.questions,
            }
            history.push("/result", { data:data})
            // this.audio.pause()
            // var results = <Results {...this.state} />
            // props.history.replace({
            //     // pathname: '/result'+ result._id,
            //     pathname: '/result',
            //     // state: { detail: res}
            //     state: { notice: "true" , message:"Create Event",data:data}
            // })
            
        }
    }

    const meMen=()=>{
        // setCurrent(current+1)
    }
   
return(
 
    

   
    <main class="main  absolute top-0 main-body  " >
        <div className="questions">
            <div className="question-time">
              <CountdownCircleTimer
                        isPlaying
                        duration={140}
                        strokeWidth={3} 
                        size={58} 
                        colors={[
                          ['#004777', 0.33],
                          ['#F7B801', 0.33],
                          ['#A30000', 0.33],
                        ]}
                     
                      >
                    {({ remainingTime }) => remainingTime}
                      
             </CountdownCircleTimer>
            </div>
       
           
        
        
            {
            props.questions.map(question => {
                if (question.id  == current) {
                
                    return <Question score={score} getScore={getScore} totar={ props.questions.length} 
                    passCorrent={current} question={question} getCurrent={getCurrent}
                    key={question.id} {...props} />
                }

            })
            }
            
         </div>
      </main>
    )
  
}


export default QuestionList;