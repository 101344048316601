import React, {Component} from 'react';
// import "./loader.css"
class Loader extends Component{

  render() {
    
    return(
      <div className="">
        <div class="loading">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
         </div>
      </div>
    )
  }
}

export default Loader;
 