import React, { useState, useEffect } from 'react';
import  AuthService  from "../../api/authentication.js"
import {URL_EXAM_END, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import { ToastContainer, toast } from 'react-toastify';
import { useParams , withRouter } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
const Auth = new AuthService();


function Step(props) {

    const [last, setList] = useState(null);
    const [first, setFirst] = useState(null);
    const [phone, setPhone] = useState(null);
    const [address, setAddress] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(null);
    const [sex, setSex] = useState(null);

    useEffect(async () => {
      props.updateTitle('setting')
        setLoading(true);
        props.error404(false)
        const fetchData = async () => {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' , 
                'Authorization':'Bearer '  +Auth.getToken()
                }
              }
            fetch(URL_EXAM_END+"/user/"+Auth.getProfile().id,requestOptions)
            .then(res => res.json())
            .then(
              (res) => {
                  setList(res.firstname)
                  setFirst(res.lastname)
                  setAddress(res.address)
                  setPhone(res.phone)
                  setUser(res)
                  setLoading(false);
              
                },
              (error) => {
                setLoading(false);
              
              }
         )
       }  
        fetchData();
    },[])


    const  handleTerm = (e) => {    
        setSex(e.target.value) 
     }
  
    const submit =()=>{
        setLoading(true)
        // console.log("lol data")
        if(sex ===  null){
            setLoading(false)
            toast("Select sex")
           }else {
            const data ={
                firstname:first,
                lastname:last,
                address:address,
                phone:phone,
                step1:true,
                sex:sex,
            }   
          //  console.log("data---=--->", data)
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type':'application/json' , 
                'Authorization':'Bearer '  +Auth.getToken()
                },
                body: JSON.stringify(data),
            };
            console.log("requestOptions",requestOptions)
            fetch(URL_EXAM_END+"/user/"+Auth.getProfile().id, requestOptions)
            .then(res => res.json())
            .then((res)=>{
                // console.log("res===---------->", res)   
                if(res.status === false){

                    setLoading(false)
                    toast("Updated not successful ")

                    // console.log("res", res)    
                }else {
                    window.location.href = '/articles'
                  toast("Updated successful ")
                    setLoading(false)
                }
            }) .catch((error) => {
              toast("Updated not successful ")
                // console.error('Error:', error);
              });
           }
      }



    return(
       <div className="setting-body">
             <ToastContainer />
           <div className="setting-title"> 
             <h2> Profile Setting   </h2>      
           </div>

           <div style={{marginTop:"3%"}}>
              <div class="field-body">
                <div class="field">
               
                    <input class="input" type="text" placeholder="First Name" onChange={(e)=>setFirst(e.target.value)} value={first} />
                  
              
                </div>
                <div class="field">
          
                    <input class="input is-success" type="text" placeholder="Last Name" onChange={(e)=>setList(e.target.value)} value={last}/>
              
                </div>
              </div>
              <br/>
               <div class="field-body">
                <div class="field">
                    <div class="select" >
                        <select onChange={handleTerm}  value={sex} style={{width:"250px"}} >
                                <option >Sex</option>
                                <option value={"male"}>Male</option>
                                <option value={"female"}>Female</option>
                            </select>
                     </div>
                </div>
                <div class="field">
             
                    <input class="input is-success" type="number" style={{width:"100%"}} placeholder="Phone" onChange={(e)=>setPhone(e.target.value)} value={phone}/>
               
                </div>
              </div>
               <br/>
              <input class="input" type="text" placeholder="Address" onChange={(e)=>setAddress(e.target.value)} value={address}/>
        

              <div class="control" style={{marginTop:"5%",textAlign:"center"}}>
              {
                loading ?
                    <p style={{textAlign:"center"}}>
                        <button class="button is-white   is-loading" >Loading</button>
                    </p>
                    :
                    <p style={{textAlign:"center"}}>
                          <button class="button is-info is-rounded" onClick={submit} style={{width:"30%"}}>Sumit</button>
                    </p>
                  }
                
                </div>
           </div>
    
       </div>
    )
} 

export default withRouter(Step)