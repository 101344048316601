import React, {useState,useEffect} from 'react';
import axios from 'axios';
import './onboarding.css';
import {URL_EXAM_END, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import { withRouter } from "react-router-dom";

function Onboarding(props) {

    useEffect(()=> {
        props.error404(false)
    
     },[])
    const [loading, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)
    const [errormessage, setErrormessage] = useState(null)
    const [email , setEmail ] = useState(null)
    const [password , setPassword ] = useState(null)
    const [fullname, setFullname] = useState(null)

   
    const [state , setState] = useState({
        email : "",
        password : "",
         username:"",
        confirmPassword: "",
        successMessage: null
    })
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }
    const sendDetailsToServer = (e) => {
        e.preventDefault()
        // console.log("data=--------API_BASE_URL",API_BASE_URL,state.password,state.email, state.username)
        if(state.email.length && state.password.length) {
            setIsLoaded(true)
            props.showError(null);
                const login ={
                    username: state.username,
                    email : state.email,
                    password : state.password,
                }
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(login),
                };
            fetch(URL_EXAM_END+"/api/registart", requestOptions)
            .then(res => res.json())
            .then((res)=>{
               console.log("res", res)
            //    setIsLoaded(false)
            //     if(res.status === "error"){
            //     }
            if(res.status === true){
                // console.log("res ----------->true", res)
                // this.setState({
                //     successful: true,
                //     emailerror:false, 
                // }); 
                props.history.replace({
                   pathname: '/login',
                   state: { notice: "true" , data:2 }
                 })
            }else{
               if(res.err){  
                   if(res.err === 401) {
                       this.timeout()
                    
                        //    this.setState({
                        //        errormes:true,
                        //        mesageusername: res.mas,
                        //        loadingButton :false    
                        //    })
                       }
                       if(res.err === 402) {
                        //    this. timeout()
                          
                        //    this.setState({
                        //        errormes:true,
                        //        loadingButton :false,
                        //        emailmesage: res.mas,      
                        //    })
                       }  
                     }
                 }
       
                 }).catch( (error) => {
                   
              });
      
        } else {
            props.showError('Please enter valid username and password')    
        }
        
    }

    const redirectToHome = () => {
        props.updateTitle('Home')
        props.history.push('/home');
    }

    const redirectToLogin = () => {
        props.updateTitle('Login')
        props.history.push('/login'); 
    }

    const handleSubmitClick = (e) => {
        e.preventDefault();
        if(state.password === state.confirmPassword) {
            sendDetailsToServer()    
        } else {
            props.showError('Passwords do not match');
        }
    }
    return(
        <main class="main  absolute top-0 main-body" >
        
             
                <div class="login-body">
                    <div className="login-info"> 
                      <p> <img src="https://res.cloudinary.com/codepally/image/upload/v1626645776/Ridora/png/unnamed_1_tvmbac.png" /></p>
                      <h1>Building the future of your children at a young age.</h1>

                
                    </div>
                    <div className="onboarding-input">
                    <div className="onboarding-from">
                        <h3>Let’s Get Started</h3>
                
                        {/* <p>Sign Up by entring the informations below</p>  */}
                    </div>    
                        <div class="tabcontent mt-5 " >
                      
                            <div className="input-info-holder  onboarding-info-width">
                            <div className="onboarding-p"> 
                              <p>Parents / Guidian’s  Detail</p>
                            </div>
                         
                            <div style={{marginBottom:"5%"}}>
                                 <input type="text"  class="input mt-1 block w-full info-text py-2 px-2  bg-white  focus:outline-none"
                         
                             
                                    aria-describedby="emailHelp" 
                                    placeholder="Fullname" 
                                    value={state.fullname}
                                    onChange={(e)=>setFullname(e.target.value)}
                                 />
                                </div>

                                {/* <div style={{marginBottom:"5%"}}>
                                 <input type="text" placeholder="Email" class="input mt-1 block w-full info-text py-2 px-2  bg-white  focus:outline-none"
                                    value={email}
                                    value={state.confirmPassword}
                                    onChange={(e)=>setEmail(e.target.value)}
                                 />
                                </div> */}

                                <div class="grid grid-cols-6 gap-3 mt-3">
                                        <div class="col-span-6 lg:col-span-3">
                                            {/* <label for="name" class="block font-10 primary-color ">First Name</label> */}
                                            <input type="text" placeholder="Email"   
                                                value={email}                   
                                                onChange={(e)=>setEmail(e.target.value)}
                                             class=" input  mt-1 block w-full info-text py-2 px-2  bg-white  focus:outline-none"/>

                                        </div>
                                        <div class=" col-span-6 lg:col-span-3 ">
                                        <div class="select " style={{marginTop:"2%"}}>
                                                <select>
                                                    <option>Select Gender </option>
                                                    <option>Male</option>
                                                    <option>female</option>
                                                </select>
                                                </div>
                                        </div>     
                               </div>
                                 {/* <br/> */}
                                 <div className="onboarding-p"> 
                                     <p>Student Detail</p>
                                 </div>
                                <div>
                                        <div style={{marginBottom:"5%"}}>
                                        <input type="text" placeholder="Fullname" class="input mt-1 block w-full info-text py-2 px-2  bg-white  focus:outline-none"
                                        value={password}
                                        onChange={(e)=>setPassword(e.target.value)}
                                        />
                                        </div>
                                 
                                </div>    
                                
                                <div class="grid grid-cols-6 gap-3 mt-3">
                                        <div class="col-span-6 lg:col-span-3">
                                            {/* <label for="name" class="block font-10 primary-color ">First Name</label> */}
                                            <input type="text" placeholder="Class"   
                                                value={email}                   
                                                onChange={(e)=>setEmail(e.target.value)}
                                             class=" input  mt-1 block w-full info-text py-2 px-2  bg-white  focus:outline-none"/>

                                        </div>
                                        <div class=" col-span-6 lg:col-span-3 ">
                                        <div class="select " style={{marginTop:"2%"}}>
                                                <select>
                                                    <option> Current Term </option>
                                                    <option>first Term</option>
                                                    <option>Second Term</option>
                                                </select>
                                                </div>
                                        </div>     
                               </div>
                               <div class="grid grid-cols-6 gap-3 mt-3">
                                        <div class="col-span-6 lg:col-span-3">
                                            {/* <label for="name" class="block font-10 primary-color ">First Name</label> */}
                                            <input type="text" placeholder="Age"   
                                                value={email}                   
                                                onChange={(e)=>setEmail(e.target.value)}
                                             class=" input  mt-1 block w-full info-text py-2 px-2  bg-white  focus:outline-none"/>

                                        </div>
                                        <div class=" col-span-6 lg:col-span-3 ">
                                        <div class="select " style={{marginTop:"2%"}}>
                                             <select>
                                                    <option>Select Gender </option>
                                                    <option>Male</option>
                                                    <option>female</option>
                                                </select>
                                                </div>
                                        </div>     
                               </div>
                               <div className="onboarding-p"> 
                                     <p>School Detail</p>
                                 </div>
                                 <div class="grid grid-cols-6 gap-3 mt-3">
                                        <div class="col-span-6 lg:col-span-3">
                                            {/* <label for="name" class="block font-10 primary-color ">First Name</label> */}
                                            <input type="text" placeholder="School"   
                                                value={email}                   
                                                onChange={(e)=>setEmail(e.target.value)}
                                             class=" input  mt-1 block w-full info-text py-2 px-2  bg-white  focus:outline-none"/>

                                        </div>
                                        <div class=" col-span-6 lg:col-span-3 ">
                                        <input type="text" placeholder="Address"   
                                                value={email}                   
                                                onChange={(e)=>setEmail(e.target.value)}
                                             class=" input  mt-1 block w-full info-text py-2 px-2  bg-white  focus:outline-none"/>

                                        </div>     
                               </div>
                                    {
                                        error ?
                                            <p style={{textAlign:"center",marginBottom:"5px",marginTop:"4%",color:"red"}}>{errormessage}</p>
                                        :
                                            <></>
                                    }
                                  <br/>
                                 <div className="onboarding-bottom">
                                                {
                                                    loading ?
                                                  <p>  <button class="button is-loading"  style={{width:"50%"}}>Loading</button> </p>
                                                    :
                                                  <p>  <button class="button is-info is-rounded" style={{width:"50%"}}
                                                    onClick={sendDetailsToServer}
                                                            >
                                                        Submit
                                                    </button>
                                                    </p>
                                                }
                                            </div>
                                {/* <p>
                                <button class="button is-info">Submit</button>
                                </p> */}
                             </div>
                        </div>
                    </div>
                </div>
           
         </main>  
    )
}

export default withRouter(Onboarding);