import React, {useState,useEffect} from 'react';
import Question from './Question';
import { CountdownCircleTimer } from 'react-countdown-circle-timer' 
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

import "./result.css";

const Result = (props) => {
    const [current, setCurrent] = useState(1)
    const [score, setScore] = useState(props.location.state.data. score)
    const [questions, setQuestions] = useState(props.location.state.data.questions)
    // const [Leader ,setLeader]= useState()
    // const [score ,setScore] = useState()
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const [reviewsTab , setReviewsTab] =useState(true)
    const [statistTab , setStatistTab] =useState(false)
    const [leaderTab , setLeaderTab] =useState(false)
    const [message, setMessage] =useState(null)
    const [percent, setPercent]=useState(null)


    useEffect(()=> {
        props.error404(false)
        let percent1 = (props.location.state.data.score / props.location.state.data.questions.length * 100);
        setPercent(percent1)
        if (percent1 > 80) {
           setMessage('Awesome Work!')
        } else if (percent1 < 80 && percent1 > 60) {
          setMessage('You Did ok! keep keep the good working ')
        } else {
            setMessage('Not Good! Keep Fighting ')
        }

    },[])


    const Leader = () =>{
        setLeaderTab(true)
        setReviewsTab(false)
        setStatistTab(false)
    }

    const Reviews = ()=>{
        setLeaderTab(false)
        setReviewsTab(true)
        setStatistTab(false)
    }

    const Statist = ()=>{
        setLeaderTab(false)
        setReviewsTab(false)
        setStatistTab(false)
    }
 
   
   
return(

        <div >
            <div className="result-full-body">
                <div className="result-heard">
                  {
                      percent === null ?
                      ""
                      :
                      <h3>{percent.toFixed(2)}% - {message}</h3>
                  }
                
                    <div>
                        <img src="https://quizac.com/fonts/Win_Confetti%20Asset.svg"/>
                    </div>

                       <h4>Your Points</h4>
                        <h5>{score}</h5>
                        <h6>You Got {score} out of {questions.length} Correct</h6>  
                    
                    <ul>
                        <li>
                        <a href="/home" class="button is-info is-rounded">Start again</a>
                        </li>    
                        <li>
                        <button class="button is-success is-rounded">Exam</button>
                        </li>
                    </ul>  
                </div>
                <div className="result-sub-nav">
                    <section class="hero is-success  result-section-tab">
                            
                            <div class="hero-foot">
                                    <nav class="tabs is-boxed is-fullwidth ">
                                            <div class="container">
                                            <ul>
                                                <li class={leaderTab?"tab is-active": "tab"} onClick={Leader}><a > Leaderboard</a></li>
                                                <li class={reviewsTab?"tab is-active" : "tab "} onClick={Reviews}><a >Reviews</a></li>
                                                <li class={statistTab?"tab is-active" : "tab "} onClick={Statist} ><a >Statistics</a></li>
                                            </ul>
                                            </div>
                                        </nav>
                                    </div>
                    </section>
                    <div className="tab-header-title">
                    {/* {tabName} */}
                    </div>
                    <div class="  result-tab-section">
                        {
                         leaderTab ?
                            <div id="" class="" >
                              <table class="table is-fullwidth">
                                <thead className="has-background-dark thead">
                                    <tr >
                                    <th style={{color:"white"}}><abbr title="Position">POINTS</abbr></th>
                                
                                    <th style={{color:"white"}}><abbr title="Played">QUESTIONS </abbr></th>
                                    <th style={{color:"white"}}><abbr title="Played">TEACHTER</abbr></th>
                                    </tr>
                                
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th> 50%</th>
                                        <th>Lego 101</th>
                                        <th>Emal john</th>
                                    </tr>
                                    <tr>
                                        <th>20%</th>
                                        <th>Lego 101</th>
                                        <th>chukwuka john</th>
                                    </tr>
                                </tfoot>

                            </table>
                         </div>
                        :
                           ""
                        }
                        {
                        reviewsTab? 
                        <div>
                            {
                               questions === undefined ?
                                ""
                                :
                                <AutoplaySlider
                            animation="cubeAnimation"
                                
                                play={true}
                                cancelOnInteraction={false} // should stop playing on user interaction
                                interval={6000}
                            >
                                    {
                                   questions.map((data, index) => 
                                        <div className="result-carosel-body" key={data.id}>
                                        <h1>Q1/Q{data.id}</h1>
                                        <p>{data.question}</p>
                                        <h2>Answer</h2>
                                            <h3>{data.correct}</h3>
                                            
                                            {
                                            data.choices.map((current, index) =>
                                            <div>
                                                {
                                                    current.id === data.correct ?
                                                    <p style={{color:"black",position:"relative",bottom:"20px"}}>
                                                            {current.text}
                                                    </p>
                                                    :
                                                    ""
                                                }
                                            </div>
                                            )}
                                            
                                        </div>
                                        )
                                    }
                                    </AutoplaySlider>
                            
                            }

                            </div>
                            :
                            ""
                        }
                        
                        {
                            statistTab?
                                <div id="Support" class="content-tab" style={{display:"none"}}>
                                    <p>
                                        test3 <br/>  
                                    Specification Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                </div>
                            :
                            ""
                        }      
                        </div>
                  </div>
             </div>
        </div>
 
    )
  
}


export default Result;