import React, { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import { CountdownCircleTimer } from 'react-countdown-circle-timer' 
// var question = require('./data.js').default;
import  Examlist  from "./QuestionList.js"
import  getQuestion  from './data.js' 
import {API_BASE_URL,URL_EXAM_END, API_BASE_LOCAL,ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import axios from "axios"
import { useHistory } from "react-router-dom";

// import './AlertComponent.css';
import "./quiz.css";

const Exam = (props) => {
    // const  [question ,setQuestion] = useState(getQuestion.quiz.question)    
     const [current, setCurrent]  = useState(1)
     const [exam, setExam]  = useState(false)
     const [question, setQuestion] = useState(null)
     const  [questions ,setQuestions] = useState(null)  
     const history = useHistory();
     const id = props.match.params;

    useEffect(()=> {
        props.error404(false)
        props.updateTitle('categories')
        // console.log("data ------exam -----> 30",id.id)
      //   props.error404(false)
        const fetchData =() => {

          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify(login),
        };
          fetch(URL_EXAM_END+"/details/exam/"+id.id,requestOptions)
            .then(res => res.json())
            .then(
              (res) => {
                // console.log("data question ------article----->",res)
                if(res.status === true ){
                  setQuestion(res.data)
                  setQuestions(res.data.question.questions)
                }else{
                  
                  setQuestions([])
                  setQuestion(null)
                  history.push("/verification-code",)
                }
               
                
                },
                (error) => {
              }
         )
         
         
          }  
        fetchData();
    },[])

    const setScore =()=>{

    }

    const MakeCurrent = ()=>{

    }

    const sendData = () =>{

      const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json' },
   
     };

     fetch("http://3.101.85.83/json.php?&apiKey=Switch!2020", requestOptions)
     .then(res => res.json())
     .then((res)=>{
      // console.log("res", res)

     })
    }


    const ToPlay = ()=>{
      setExam(true)
    }

return(
    <>
      {
          exam ?
            <Examlist getDetails={question}  questions={questions} current={current} setCurrent={MakeCurrent} setScore={setScore} />
          :
          <div>
              {
                question === null ?
                  <div> </div>
                :
                 <div> 

                    <div className="quiz-body">
                        <div className="quiz-head">
                          <h1>   Are you ready to take your Class Test! <b style={{color:"green"}}> {question.name}  </b></h1>
                        </div>
                        <div className="quiz-ridora-logo">
                          <img src="https://res.cloudinary.com/codepally/image/upload/v1622890948/ridora%20website/ridora_logo_dlzom7.svg"/>
                        </div>

                        <div className="quiz-taking">
                              <div className="course-quiz-head " >
                              <h6>{question.name} </h6>
                              <br/>
                              <img src="https://res.cloudinary.com/codepally/image/upload/v1628339354/ridora%20website/robot_2_jigtub.svg" /> 
                                  <h5>{question.question.course.name}</h5>
                                  <h6>{question.question.name}</h6>
                                
                                  
                              </div>
                              <div className="quiz-button-to-start">
                                <a href="/verification-code" class="button is-normal quiz-button-to-back" > <i class="fas fa-arrow-left"></i> Black </a>
                                <button class="button is-normal quiz-button-to-next" onClick={ToPlay}>Start</button>
                              </div>
                              
                        </div>
                        <div>
                        
                      </div>
                    </div>

                 </div>
              }

          </div>
          
      }
    
    </>
)


}


export default Exam ;