// import React from 'react';
// import { Redirect, Route } from "react-router-dom";
// import { ACCESS_TOKEN_NAME } from '../constants/apiConstants';
// function PrivateRoute({ children, ...rest }) {
//     return (
//       <Route
//         {...rest}
//         render={({ location }) =>
//           localStorage.getItem(ACCESS_TOKEN_NAME) ? (
//             children
//           ) : (
//             <Redirect
//               to={{
//                 pathname: "/login",
//                 state: { from: location }
//               }}
//             />
//           )
//         }
//       />
//     );
//   }

// export default PrivateRoute;


import React from "react";
import { Route, Redirect } from "react-router-dom";
// import auth from "./auth";
import AuthService from "../api/authentication";
const Auth = new AuthService();
export const PrivateRoute = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (Auth.loggedIn()) {
          return<Component {...props} {...rest} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};
