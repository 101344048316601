import React, {useState,useEffect} from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer' 
// import {API_BASE_URL, API_BASE_LOCAL, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import {API_BASE_URL,URL_EXAM_END, API_BASE_LOCAL,ACCESS_TOKEN_NAME} from '../../constants/apiConstants';

import "./dashboard.css";

const Dashboard= (props) => {
    const [current, setCurrent] = useState(1)
    const [categories, setCategories] = useState([])
    const [loaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)

    
 

    useEffect(()=> {
        props.error404(false)
      //   setIsLoaded(true);
      //   setidArticle(uuidv4())
        props.updateTitle('categories')
        props.error404(false)
        const fetchData = async () => {
            fetch(URL_EXAM_END+"/courses")
            .then(res => res.json())
            .then(
              (result) => {
                  // console.log("data ------article----->",result)
                setIsLoaded(false);
                setCategories(result.data);
              },
              (error) => {
                setIsLoaded(false);
                setError(error);
              }
         )
      }  
        fetchData();
    },[])


    const clickSubmit =()=>{

    }

   
   
return(

        <div className="dashboard-body">
           <div className="sidenav">
                <div className="sidenav-name-sub">
                  <p>p</p>
                </div>

                <h3>Oluch ojo</h3>
                <h5>Current Class: JSS 2</h5>

                <div className="dashboard-list">
                      <div className="dashboard-list-sub">
                          <i class="fas fa-snowboarding"></i>
                                <p>Activities</p>
                      </div>
                      <div className="dashboard-list-sub">
                      <i class="fas fa-chalkboard-teacher"></i>
                                <p>Course</p>
                      </div>
                      <div className="dashboard-list-sub">
                      <i class="fas fa-cog"></i>
                                <p>Profile</p>
                      </div>
                      
                 </div>
           </div>

           <div className="dashboard-main">
                     <div className="dashboard-title">
                        <div className="dashboard-title-title">
                           <p>Course Title</p>
                        </div>
                        <div className="dashboard-title-modules">
                        <p>Modules</p>
                        </div>
                        <div className="dashboard-title-status">
                           <p>Status</p>
                        </div>
                        <div className="dashboard-title-remark">
                           <p>Remark</p>
                        </div>
                     </div>

                 {
                     categories.map(item => {
                          return(
                               <a href={`/courses/${item._id}`}>
                                    <div className="dashboard-course">
                                          <div className="dashboard-title-title">
                                             <img src={item.photo} />
                                             <h5>{item.name}</h5>
                                             <h6>Modules </h6>
                                          </div>
                                          <div className="dashboard-title-modules">
                                             {
                                                item.course === undefined ?
                                                   <div className="dashboard-title-modules-sub"> 
                                                      0
                                                   </div>
                                                :
                                                   <div className="dashboard-title-modules-sub">
                                                      {item.course.length}
                                                   </div>
                                             }
                                             
                                          </div>
                                          <div className="dashboard-title-status">
                                             <div className="dashboard-title-status-sub">
                                                Pending
                                             </div>    
                                          </div>
                                          <div className="dashboard-title-remark">
                                             <p>Null</p>
                                          </div>
                                    </div>

                               </a>
                               
                              )
                          })
                   }      
              
              
               {/* <div className="dashboard-course">
                   
                 <div className="dashboard-title-title">
                     <img src="https://res.cloudinary.com/codepally/image/upload/v1628339321/ridora%20website/Group_12946_jenmfz.svg" />
                     <h5>App Inventor</h5>
                     <h6>Sub- Title of Course </h6>
                  </div>
                  <div className="dashboard-title-modules">
                     <div className="dashboard-title-modules-sub">3</div>
                  </div>
                  <div className="dashboard-title-status">
                     <div className="dashboard-title-status-sub">
                     Pending
                     </div>    
                  </div>
                  <div className="dashboard-title-remark">
                      <p>Null</p>
                  </div>
               </div> */}
               {/* <div className="dashboard-course">
                  <div className="dashboard-title-title">
                        <img src="https://res.cloudinary.com/codepally/image/upload/v1628339338/ridora%20website/program_1_wzw5vf.svg" />
                        <h5>Electronics</h5>
                        <h6>Sub- Title of Course </h6>
                     </div>
                     <div className="dashboard-title-modules">
                        <div className="dashboard-title-modules-sub">3</div>
                     </div>
                     <div className="dashboard-title-status">
                        <div className="dashboard-title-status-sub">
                        Pending
                        </div>    
                     </div>
                     <div className="dashboard-title-remark">
                        <p>Null</p>
                     </div>
                </div> */}

               {/* <div className="dashboard-course">
               <div className="dashboard-title-title">
                     <img src="https://res.cloudinary.com/codepally/image/upload/v1628339354/ridora%20website/robot_2_jigtub.svg" />
                     <h5>Robotics</h5>
                     <h6>Sub- Title of Course </h6>
                  </div>
                  <div className="dashboard-title-modules">
                     <div className="dashboard-title-modules-sub">3</div>
                  </div>
                  <div className="dashboard-title-status">
                     <div className="dashboard-title-status-sub">
                     Pending
                     </div>    
                  </div>
                  <div className="dashboard-title-remark">
                      <p>Null</p>
                  </div>
               </div> */}
           </div>
            
        </div>
 
    )
  
}


export default Dashboard;