import React, {useState,useEffect} from 'react';
import axios from 'axios';
import './RegistrationForm.css';
import {URL_EXAM_END, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import { withRouter } from "react-router-dom";

function RegistrationForm(props) {

    useEffect(()=> {
        props.error404(false)
    
     },[])
    const [loading, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)
    const [errormessage, setErrormessage] = useState(null)
    const [email , setEmail ] = useState(null)
    const [password , setPassword ] = useState(null)

   
    const [state , setState] = useState({
        email : "",
        password : "",
         username:"",
        confirmPassword: "",
        successMessage: null
    })
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }
    const sendDetailsToServer = (e) => {
        e.preventDefault()
        // console.log("data=--------API_BASE_URL",API_BASE_URL,state.password,state.email, state.username)
        if(state.email.length && state.password.length) {
            setIsLoaded(true)
            props.showError(null);
                const login ={
                    username: state.username,
                    email : state.email,
                    password : state.password,
                }
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(login),
                };
            fetch(URL_EXAM_END+"/api/registart", requestOptions)
            .then(res => res.json())
            .then((res)=>{
               console.log("res", res)
            //    setIsLoaded(false)
            //     if(res.status === "error"){
            //     }
            if(res.status === true){
                // console.log("res ----------->true", res)
                // this.setState({
                //     successful: true,
                //     emailerror:false, 
                // }); 
                props.history.replace({
                   pathname: '/login',
                   state: { notice: "true" , data:2 }
                 })
            }else{
               if(res.err){  
                   if(res.err === 401) {
                       this.timeout()
                    
                        //    this.setState({
                        //        errormes:true,
                        //        mesageusername: res.mas,
                        //        loadingButton :false    
                        //    })
                       }
                       if(res.err === 402) {
                        //    this. timeout()
                          
                        //    this.setState({
                        //        errormes:true,
                        //        loadingButton :false,
                        //        emailmesage: res.mas,      
                        //    })
                       }  
                     }
                 }
       
                 }).catch( (error) => {
                   
              });
      
        } else {
            props.showError('Please enter valid username and password')    
        }
        
    }

    const redirectToHome = () => {
        props.updateTitle('Home')
        props.history.push('/home');
    }

    const redirectToLogin = () => {
        props.updateTitle('Login')
        props.history.push('/login'); 
    }

    const handleSubmitClick = (e) => {
        e.preventDefault();
        if(state.password === state.confirmPassword) {
            sendDetailsToServer()    
        } else {
            props.showError('Passwords do not match');
        }
    }
    return(
        <main class="main  absolute top-0 main-body" >
        
             
                <div class="login-body">
                    <div className="login-info"> 
                      <p> <img src="https://res.cloudinary.com/codepally/image/upload/v1626645776/Ridora/png/unnamed_1_tvmbac.png" /></p>
                      <h1>Building the future of your children at a young age.</h1>
                    </div>
                    <div className="registart-input">
                    <div className="login-from">
                        <p>
                            <img src="https://res.cloudinary.com/codepally/image/upload/v1622890948/ridora%20website/ridora_logo_dlzom7.svg"/>
                        </p>
                        <h3>Sign up </h3>
                    </div>    
                        <div class="tabcontent mt-5 " >
                            <div className="input-info-holder  input-info-width">

                            <div style={{marginBottom:"5%"}}>
                                 <input type="text" placeholder="Email" class="input mt-1 block w-full info-text py-2 px-2  bg-white  focus:outline-none"
                                    value={email}
                             
                                    aria-describedby="emailHelp" 
                                    placeholder="Enter Username" 
                                    value={state.username}
                                    onChange={(e)=>setEmail(e.target.value)}
                                 />
                                </div>

                                <div style={{marginBottom:"5%"}}>
                                 <input type="text" placeholder="Email" class="input mt-1 block w-full info-text py-2 px-2  bg-white  focus:outline-none"
                                    value={email}
                                    value={state.confirmPassword}
                                    onChange={(e)=>setEmail(e.target.value)}
                                 />
                                </div>


                                <div style={{marginBottom:"5%"}}>
                                 <input type="password" placeholder="Paaword" class="input mt-1 block w-full info-text py-2 px-2  bg-white  focus:outline-none"
                                  value={password}
                                  onChange={(e)=>setPassword(e.target.value)}
                                 />
                                </div>

                                <div style={{marginBottom:"5%"}}>
                                 <input type="text" placeholder="Email" class="input mt-1 block w-full info-text py-2 px-2  bg-white  focus:outline-none"
                                   
                                   id="confirmPassword" 
                                   placeholder="Confirm Password"
                                   value={state.confirmPassword}
                                   onChange={handleChange} 
                                 />
                                </div>
                                    {
                                        error ?
                                            <p style={{textAlign:"center",marginBottom:"5px",marginTop:"4%",color:"red"}}>{errormessage}</p>
                                        :
                                            <></>
                                    }

                                 <div className="login-bottom">
                                                {
                                                    loading ?
                                                  <p>  <button class="button is-loading" >Loading</button> </p>
                                                    :
                                                  <p>  <button class="button is-info is-rounded"
                                                    onClick={sendDetailsToServer}
                                                            >
                                                        Submit
                                                    </button>
                                                    </p>
                                                }
                                            </div>
                                {/* <p>
                                <button class="button is-info">Submit</button>
                                </p> */}
                             </div>
                        </div>
                    </div>
                </div>
           
         </main>  
    )
}

export default withRouter(RegistrationForm);