import React, {useState} from 'react';
import { Transition } from 'react-transition-group';
// import  bellAudio  from './sound/Pure.mp3';
// import  bellbad  from './sound/bad.mp3';
import { CountdownCircleTimer } from 'react-countdown-circle-timer' 
import Time from "./time"
const Question = (props) => {

   const [answered, setAnswered] = useState();

    const [selectedOption, setSelectedOption] = useState()
    const [score, setScore] = useState(0)
    // const [current, setCurrent] = useState(1)
    //   this.url = bellAudio ;
    //   this.audio = new Audio(this.url);
    //   this.audio.volume = 0.2;

    //   this.urlbad =  bellbad
    //   this.audiobad = new Audio( this.urlbad );
    //   this.audiobad.volume = 0.2;
  

 const onChange=(target, corrent)=>{
    // target.preventDefault();
    // console.log("data-------------of chich",target,corrent)
  
    setAnswered(true)
    setSelectedOption(target)

    timeout(target)
 
    // if(target === corrent){
    //   this.audio.play()
    // }else{
    //   this.audiobad.play()
    // }
    // this.timeout(target)

    // const {setCurrent, setScore, question} = this.props;

//   let selected = e.target.value;

//     if (selected === question.correct) {
//       setScore(props.score+1);

//       }

    // setCurrent(this.props.current+1);
  }

 const onChangeMovenext=(data)=>{
    // e.preventDefault();
    const { setScore, question} = props;
  
    setAnswered(false)
 
    let selected = data;
  
    if (selected === question.correct) {
      setScore(props.score+1);
      props.getCurrent(props.passCorrent+1 , props.score+1 );
     }else{
      props.getCurrent(props.passCorrent+1 , props.score+0 );
     }
    //  passingCurrent()
 
  }

  const passingCurrent=(data) =>{
    setTimeout(function() {
      props.getCurrent(props.passCorrent+1);
        // this.setState({
        //     erorrDiv : false,
        //     loadQuiz:true
        // })
    //   console.log(this.state.noticeshow);
    }.bind(this), 650);
}

  const timeout=(data) =>{
    setTimeout(function() {
      onChangeMovenext(data)
       
    }.bind(this), 900);
}


 const isCorrect = (option,conrrent) => {

  // console.log("log ans bro-------->",option.id, conrrent)
    // if(option.id  ===  conrrent ){
    //   this.audio.play()
    // }

     return option.id === conrrent;
     
};




    const {question} = props;
    return(
        <div >
            <div className="questions-time-number">
                        <div className="questions-time" > 
                          {/* <Time/> */}
                        </div>
                        <div className="questions-title" > 
                        <h2>{question.question} ?</h2>
                        </div>
                        <div className="questions-number" > 
                            <h4>{props.passCorrent} of {props.totar}</h4>
                            <h6>Questions</h6>
                        </div>
                        
                </div>
                <div className="questions-img"> 
                    <p>
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1628160773/ridora%20website/Robots_wzsg1k.svg"/>
                    </p>
                </div>
                <Transition in={true} timeout={1000} appear>
                {

                     (status)=>(
                        <div className="questions-choice-boy">
                        {
                          question.choices.map(choice => {
                          return(
                              <div className={`questions-choice box-${status}`}>
                                          <div  
                                              onClick={() => onChange(choice.id, question.correct)}
                                              disabled={answered}
                                              name={question.id} value={choice.id} id={choice.id}
                                              className="question-option"
                                              className={`question-option ${answered &&
                                              isCorrect(choice ,  question.correct) &&
                                                  "correct"}
                                                  ${
                                                  answered &&
                                                  !isCorrect(choice ,  question.correct) &&
                                                  "wrong"}
                                              `}
                                              >
                  
                                              <span>
                                                  {answered ? (isCorrect(choice ,  question.correct) ? "✔" : "X") : ""}
                                              </span>
                                              
                                          {
                                              choice.text 
                                          }
                                      </div>
                                  </div>
                                  
                                  )
                              })
                           } 
                          
                      </div>
                  
                     )
                 
                }
                  </Transition>
         
           
           <div>
         
        
           </div>
            
      </div>
    )
  
}

export default Question;


